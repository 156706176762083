import { UntypedFormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { FormSearch } from "src/app/models/base/FormSearch";
import { Component, Inject } from "@angular/core";
import {
  TegettFormControl,
  TypeField,
} from "src/app/models/Tegett/tegettFormControl";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import {
  MultipleFilter,
} from "@src/app/models/Tegett/SimpleFilterData";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";
import { EndPointMethods } from "@src/app/models/base/filterModel";

@Component({
  selector: "app-cargosSettings",
  template: "",
})
export class CargosSettings implements BaseSearchSetting {

  tiposDocumentos: MultipleFilter;


  constructor(
    @Inject(Router) private router: Router,
    @Inject(ActivatedRoute) private route: ActivatedRoute,
  ) {

  }
  async Load() {
    return true;


  }
  /**
   * Funcion para armar la busqueda
   */
  loadFormSearch(): FormSearch {
    const search = new FormSearch();
    search.formComponents = this.FormComponents();
    search.configSearch = {
      title: "cargos",
      titleIcon: "fa-solid fa-briefcase",
      apiSearch: ControllerApiList.Nomina.Cargos,
      apiSearchResult: "Listado",
      apiSearchVersion: 3,
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Cargo",
      MethodRequest: EndPointMethods.GET,
      buttonNewRecordClick: () => this.newRecord(),

      settings: [
        { objectKey: "id", sortV3: "desc", visible: false },
      ],
      fields: [
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },
        {
          objectKey: "nombre",
          renderLabel: "Nombre",
          module: "cargos",
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
        },
        {
          objectKey: "departamentoCargo",
          renderLabel: "Departamento",
        }
      ],
      filters: [
        { objectKey: "nombre", type: SearchTypeFilter.Text }



      ],
    };
    return search;
  }




  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  goEdit(id: number) {
    this.router.navigate(["../", id], {
      queryParams: { mode: "e" },
      queryParamsHandling: "merge",
      relativeTo: this.route,
    });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }



  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup(
      {
        // nombre
        nombre: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required],
          label: "Nombre Cargo",
          typeField: TypeField.Input,
          required: true,
          nameControl: "nombre",
        }),

        // Departamento
        idDepartamentoCargo: new TegettFormControl({
          formState: "",
          validatorOrOpts: [Validators.required],

          label: "Departamento",
          typeField: TypeField.List,
          required: true,
          dataTextField: "nombre",
          dataValueField: "id",
          importNoRequired: false,

          nameControl: "idDepartamentoCargo",
          searchType: "tiposdocumentos",
        }),

        // SubDepartamento
        idSubDepartamentoEmpleado: new TegettFormControl({
          formState: "",
          label: "SubDepartamento",
          typeField: TypeField.List,
          required: false,
          nameControl: "idSubDepartamentoEmpleado",
        }),


         // Salario base
         salario: new TegettFormControl({
          formState: 0,
          validatorOrOpts: [],
          label: "Salario Base",
          typeField: TypeField.Input,
          required: false,
          nameControl: "salario",
        }),

        // Descripcion
        descripcion: new TegettFormControl({
          formState: "",
          label: "Descripción",
          typeField: TypeField.Input,
          required: false,
          nameControl: "descripcion",
        }),


      }
    );

    return formRecord;
  }

  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1"
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2"
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3"
      }
    ];
    return filterdataRecently
  }
}
