<splus-forms id="cargosPage" [submit]="submitted" [messageProcess]="messageProcess"
  [showMessageProcess]="showMessageProcess" [FormRecord]="FormRecord">

  <form [formGroup]="FormRecord" (ngSubmit)="onSubmit($event)" autocomplete="off"
    [ngClass]="{'formGroup-read': processType == ProcessType.view}">
    <splus-forms-header [isLoading]="isLoading">
      <splus-forms-main-title [title]="'Cargos'"
        [titleData]="processType == ProcessType.view ? nombreCompleto : 'Cargos'" [icon]="icon">
      </splus-forms-main-title>

      <div *ngIf="processType == ProcessType.view || processType == ProcessType.edit">
        <div class="sub-title-info ">
          {{nombreCompleto | uppercase }}

          <div *ngIf="isLoading">

            <div class="k-hbox">
              <kendo-skeleton shape="text" animation="pulse" width="100px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="70px"></kendo-skeleton> &nbsp;
              <kendo-skeleton shape="text" animation="pulse" width="90px"></kendo-skeleton>
            </div>
          </div>


        </div>
      </div>


      <splus-forms-toolbar [options]="options">
        <splus-forms-toolbar-buttons [moreOptions]="moreOptions" [processType]="processType">

          <button type="Submit" [disabled]="isLoading"
            *ngIf="processType == ProcessType.create || processType == ProcessType.edit" kendoButton rounded="small"
            themeColor="primary">
            <i class="fa-solid fa-floppy-disk"></i> Guardar
          </button>

          <button [disabled]="isLoading"  type="button" *ngIf="processType == ProcessType.view" kendoButton
            rounded="small" themeColor="primary" (click)="goEdit()">
            <i class="fa-solid fa-pencil"></i> Editar
          </button>

          <button *ngIf="processType == ProcessType.edit" type="button" kendoButton rounded="small" themeColor="base"
            (click)="cancelEdit()">
            Cancelar
          </button>

          <button *ngIf="processType == ProcessType.view" type="button" kendoButton rounded="small" themeColor="base"
            (click)="goback()">
            Volver
          </button>

          <button *ngIf="processType == ProcessType.create" type="button" kendoButton rounded="small" themeColor="base"
            (click)="clearForm()">
            Limpiar
          </button>

        </splus-forms-toolbar-buttons>
      </splus-forms-toolbar>


    </splus-forms-header>

    <splus-forms-fields [processType]="processType">




      <splus-forms-group [title]="'Información Básica'" #grupoBasico>


        <splus-field #nombre *ngVar="f.nombre as ctrl" [splusFormControl]="ctrl">
          <kendo-textbox splusInput [splusField]="nombre" [splusGroup]="grupoBasico" type="text" [id]="ctrl.nameControl"
            [formControl]="ctrl" (blur)="buscarRegistroPorNombre(ctrl.value)">

            <ng-template kendoTextBoxPrefixTemplate>
              <div class="m-1">
                <i [ngClass]="icon"></i>
              </div>
              <kendo-textbox-separator></kendo-textbox-separator>
            </ng-template>

            <ng-template kendoTextBoxSuffixTemplate>
              <div *ngIf="searchingDocument" class="k-i-loading w-5">&nbsp;</div>
            </ng-template>

          </kendo-textbox>

        </splus-field>
        <splus-field *ngVar="f.idDepartamentoCargo as ctrl" [splusFormControl]="ctrl" #idDepartamentoCargo>
          <kendo-dropdownlist #select splusInput [splusField]="idDepartamentoCargo" [splusGroup]="grupoBasico"
            [formControl]="ctrl" [data]="departamentoCargo" [textField]="'nombre'" [valueField]="'id'" (valueChange)="ObtenerSubDepartamentos($event)"
            [valuePrimitive]="true">
          </kendo-dropdownlist>

          <ng-template splusFieldViewModeTemplate>
            {{select["text"]}}
          </ng-template>
        </splus-field>
      <splus-field *ngVar="f.idSubDepartamentoEmpleado as ctrl" [splusFormControl]="ctrl">
          <kendo-dropdownlist #select splusInput [splusGroup]="grupoBasico" [formControl]="ctrl"
              [data]="SubDepartamentoCargo" [textField]="'nombre'" [valueField]="'id'"
              [clearButton]="select.isOpen" [valuePrimitive]="true">

              <ng-template kendoDropDownListNoDataTemplate>
                  <div>
                      <i class="fa-sharp fa-regular fa-circle-exclamation"></i><br />
                      No hay datos.<br /> por favor seleccione un departamento.
                  </div>
              </ng-template>

          </kendo-dropdownlist>

          <ng-template splusFieldViewModeTemplate>
              {{select["text"]}}
          </ng-template>
      </splus-field>
        <splus-field *ngVar="f.salario as ctrl" [splusFormControl]="ctrl">
          <kendo-numerictextbox splusInput [splusGroup]="grupoBasico" [id]="ctrl?.nameControl" [spinners]="false"
            [format]="{ currency: 'COP' }" [formControl]="ctrl"></kendo-numerictextbox>

          <ng-template splusFieldViewModeTemplate>
            {{ ctrl?.value | currency:'$':'symbol':'1.0-0' }}
          </ng-template>
        </splus-field>

      </splus-forms-group>


      <splus-forms-group [title]="'Descripción Cargo'" #grupoDescrpcion [mainLayout]="'layout-simple'">
        <splus-field *ngVar="f.descripcion as ctrl" [splusFormControl]="ctrl">
          <kendo-textarea splusInput [splusGroup]="grupoDescrpcion" type="text" [id]="ctrl.namecontrol" [rows]="3"
            [formControl]="ctrl">
          </kendo-textarea>
        </splus-field>
      </splus-forms-group>



    </splus-forms-fields>

  </form>
</splus-forms>
