import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AppSettings } from "@app/helpers/AppSettings";
import { SearchInListComponent } from "@src/app/core/Search/search-in-list/search-in-list.component";
import { CargosComponent } from "./components/cargos.component";
import { CargosSettings } from "./config/cargos.settings";

const routes: Routes = [
  {
    path: "listado",
    component: SearchInListComponent,
    data: { title: "Contratos" },
  },
  { path: "", component: CargosComponent },
  { path: ":id", component: CargosComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CargosRoutingModule {

  //Routing principal
  public static RoutingCargos = {
    path: "nomina/cargos",
    loadChildren: () =>
      import("./cargos.module").then(
        (m) => m.CargosModule
      ),
    canActivate: [AuthGuard],
    data: {
      title: `Contratos`,
      icon: "fa-duotone fa-solid fa-briefcase",
      setting: CargosSettings,
    },
  };
}
