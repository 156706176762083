import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ProcessType, SPlusDialogCloseResult, SPlusDialogSettings, SPlusFormsDialogsService, SPlusMoreOption, SPlusOption, SPlusProcessMessageComponent } from '@saludplus/forms';
import { CargosDTO } from '@src/app/models/Nomina/Cargos';
import { SplusBaseForm } from '@src/app/models/base/BaseFormComponent';
import { RoutingState } from '@src/app/services/Generals/routing.state.service';
import { AuthenticationService } from '@src/app/services/Security/authentication.service';
import { CargosSettings } from '../config/cargos.settings';
import { IntlService } from '@progress/kendo-angular-intl';
import { UtilService } from '@src/app/services/Utilities/util.service';
import { PersistenceService } from '@src/app/services/Utilities/data.persistence.service';
import { Title } from '@angular/platform-browser';
import { CargosService } from '../service/cargos.service';
import { Subject, takeUntil } from 'rxjs';
import { DefaultModelDTO } from '@src/app/models/General/DefaultModelDTO';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-cargos',
  templateUrl: './cargos.component.html',
  styleUrls: ['./cargos.component.scss']
})
export class CargosComponent extends SplusBaseForm implements OnInit, OnDestroy {
  override id = 0;
  override submitted = false;
  override isLoading = false;
  override isDeleting = false;

  // Forms
  override FormRecord: UntypedFormGroup;
  datosCargo: CargosDTO;
  nombreCompleto = "";
  override icon = "";
  // Forms - control de los estados
  override processType: ProcessType = ProcessType.create;
  override ProcessType = ProcessType;
  override showMessageProcess: boolean;
  override messageProcess = new SPlusProcessMessageComponent();
  nombreCargo = "";
  //listado de opciones --
  options: SPlusOption[] = [
    {
      id: "Listado",
      icon: "fa-solid fa-magnifying-glass",
      text: "Listado",
      link: "/nomina/cargos/listado",
    },
    {
      id: "Ayuda",
      icon: "fa-solid fa-circle-question",
      text: "Ayuda",
      action: () => {
        this.OpenDocumentacion();
      },
    },
  ];

  //listado de más opciones --
  moreOptions: SPlusMoreOption[] = [
    {
      id: "CrearCargo",
      showIn: ProcessType.view,
      text: "Crear nuevo cargo",
      icon: "fa-duotone fa-plus",
      action: () => this.newRecord(),
    },
    {
      id: "EliminarCargo",
      showIn: ProcessType.edit,
      text: "Eliminar",
      icon: "fa-solid fa-trash-can",
      action: () => this.dialogEliminar(),
    },
  ];
  departamentoCargo: DefaultModelDTO[] = []
  searchingDocument = false;
  private unsubcribe$ = new Subject<void>();

  private sPlusFormsDialogsService = inject(SPlusFormsDialogsService);
  SubDepartamentoCargo: DefaultModelDTO[];
  constructor(

    private cargosSettings: CargosSettings,
    private cargosService: CargosService,
  ) {

    super()

    this.controlProcess();

    this.routingState.loadRouting();
    this.router.events.pipe(takeUntil(this.unsubcribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.controlProcess();
      }
    });


  }

  onSubmit(event: any): void {
    event.preventDefault();
    this.submitted = true;
    if (this.FormRecord.valid) {
      if (this.FormRecord.pristine || this.searchingDocument) return;
      this.isLoading = true;
      if (this.processType === this.ProcessType.edit) {
        this.UpdateRecord();
      } else {
        this.SaveRecord();
      }
    } else {
      this.isLoading = false;
    }
  }
  SaveRecord(): void {
    this.cargosService
      .Guardar<number>(this.FormRecord.value)
      .subscribe({
        next: (res) => {
          this.isLoading = false;
          if (res) {
            this.router.navigate([`${res}`], {
              queryParams: { mode: "i" },
              relativeTo: this.route,
            });
          }

        }
      });
  }
  UpdateRecord(): void {
    this.isLoading = true;
    this.submitted = true;
    if (this.FormRecord.valid && !this.FormRecord.pristine) {
      this.cargosService
        .ActualizarRegistro<boolean>(this.FormRecord.value, this.id)
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            if (res) {
              this.router.navigate([], {
                queryParams: { mode: "u" },
                queryParamsHandling: "merge",
              });
            }


          }
        });
    } else {
      this.isLoading = false;
    }
  }
  DeleteRecord(): void {
    this.isDeleting = true;
    this.isLoading = true;
    this.cargosService.Delete<boolean>(this.id).subscribe({
      next: (res) => {
        if (res) {
          this.isDeleting = false;
          this.router.navigate(["../"], {
            queryParams: { mode: "d" },
            queryParamsHandling: "merge",
            relativeTo: this.route,
          });
        }

      }
    });
  }
  LoadRecord(): void {
    this.nombreCargo = "";
    this.isLoading = true;
    this.cargosService.BuscarRegistro<CargosDTO>(this.id).subscribe({
      next: async (res) => {
        this.isDeleting = false;
        if (res) {
          await this.ObtenerSubDepartamentos(res.idDepartamentoCargo)
          this.datosCargo = res;
          if (this.datosCargo) {
            this.nombreCargo = this.datosCargo.nombre;
            this.nombreCompleto = this.datosCargo.nombre
            this.FormRecord.patchValue(this.datosCargo);
            this.isLoading = false;


          }
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico de lado del cliente", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cargar empleado";
        dialogSettings.description =
          "Hubo un error al intentar eliminar el empleado por favor intentelo más tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
      },
    });
  }
  goEdit(): void {
    this.routingState.goEdit();
  }
  cancelEdit(): void {
    if (this.processType === ProcessType.create) {
      this.goback();
    } else if (this.processType === ProcessType.edit) {
      // validar si se modificó el formulario visualmente
      if (!this.FormRecord.pristine) {
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Cancelar edición";
        dialogSettings.isHtml = true;
        dialogSettings.description =
          "Hay cambios pendientes por guardar. <br/>¿Estás seguro que desea cancelar la edición?";
        dialogSettings.actions = [
          { text: "Seguir Editando" },
          { text: "Si, Cancelar", themeColor: "primary", value: true },
        ];
        this.sPlusFormsDialogsService
          .Show(dialogSettings)
          .pipe(takeUntil(this.unsubcribe$))
          .subscribe((result) => {
            if (result instanceof SPlusDialogCloseResult) {
              console.log("close", result);
            } else {
              if (result.value) {
                this.cancelUpdate();
              }
            }
          });
      } else {
        this.goback();
      }
    }
  }
  /**
   * Cancelar edicion
   */
  cancelUpdate() {
    this.FormRecord.patchValue(this.datosCargo);
    this.FormRecord.markAsPristine();
    this.router.navigate([], {
      queryParams: { mode: null },
      queryParamsHandling: "merge",
    });
  }
  clearForm(): void {
    this.FormRecord.reset();
    this.FormRecord.updateValueAndValidity();
    this.submitted = false;
  }
  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    this.icon = this.route.snapshot.data['icon'];
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.loadFormComponents();
    this.ObtenerDepartamentos();
    this.After();

  }
  After() {
    this.FormRecord.enable();

    if (this.id) {
      this.LoadRecord();
    }
    this.isLoading = false;

  }
  buscarRegistroPorNombre(nombre: string) {

    if (nombre === "" || nombre === this.nombreCargo) {
      return;
    }
    this.searchingDocument = true;
    this.cargosService.Validar<CargosDTO>(nombre).subscribe({
      next: (res) => {
        this.searchingDocument = false;
        if (res) {
          let dialogSettings = {} as SPlusDialogSettings;
          dialogSettings.title = "Cargo Encontrado";
          dialogSettings.description = `El nombre del cargo ${nombre} ya existe.`;
          dialogSettings.actions = [
            { text: "Cancelar" },
            { text: "Ver cargo", themeColor: "primary", value: true },
          ];
          this.sPlusFormsDialogsService
            .Show(dialogSettings)
            .pipe(takeUntil(this.unsubcribe$))
            .subscribe((result) => {
              if (result instanceof SPlusDialogCloseResult || !result.value) {
                this.searchingDocument = false;
                this.isLoading = false;
                if (this.route.routeConfig.path !== ":id") {
                  this.FormRecord.controls['nombre'].setValue("");
                } else {
                  this.FormRecord.controls['nombre'].setValue(
                    this.nombreCargo
                  );
                }
              } else {
                if (result.value) {
                  if (this.route.routeConfig.path === ":id") {
                    this.processType = this.ProcessType.view;
                    this.id = res.id;
                    this.LoadRecord();
                    this.router.navigate([
                      "nomina/cargos" + this.id,
                    ]);
                    this.searchingDocument = false;
                  } else {
                    this.processType = this.ProcessType.view;
                    this.searchingDocument = false;
                    this.router.navigate([res.id], {
                      relativeTo: this.route,
                    });
                  }
                }
              }
            });
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        console.warn("Error critico", err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Error al consultar el cargo";
        dialogSettings.description =
          "Hubo un error al intentar consultar el cargo por favor intentelo mas tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
        this.isLoading = false;
        this.searchingDocument = false;
      },
    });

  }
  /**
  * Cargar datos departamentos
  */
  ObtenerDepartamentos() {

    this.cargosService.ObtenerDepartamentos<DefaultModelDTO[]>().subscribe({
      next: (res) => {
        if (res) {
          this.departamentoCargo = res;
        }
      },
      error: (err) => {
        if (err instanceof HttpErrorResponse && err.status == 401) return;
        this.FormRecord.enable();
        console.warn(err);
        let dialogSettings = {} as SPlusDialogSettings;
        dialogSettings.title = "Error cargar listado";
        dialogSettings.description =
          "Error de comunicacion con el server por favor intentelo mas tarde";
        dialogSettings.actions = [{ text: "Cerrar" }];
        this.sPlusFormsDialogsService.Show(dialogSettings);
      },
    });
  }
  async ObtenerSubDepartamentos(data: number) {
    this.FormRecord.get("idSubDepartamentoEmpleado").reset();

    this.SubDepartamentoCargo = await this.cargosService.ObtenerSubDepartamentosAsync<DefaultModelDTO[]>(data);

  }
  /**
     * Abreviacion para acceder a los controles del FormRecord
     */
  public get f(): any {
    return this.FormRecord.controls;
  }
  /**
    * Componentes del formulario
    */
  loadFormComponents() {
    this.FormRecord = this.cargosSettings.FormComponents();
  }
  ngOnDestroy() {
    this.unsubcribe$.next();
    this.unsubcribe$.complete();
  }

  /**
   * Determina el estado de la pantalla (insercion, vista, edicion, eliminacion)
   */
  controlProcess() {
    const params = this.util.decodeURLParams(this.router.url);
    const mode = params.mode;

    if (this.route.routeConfig.path === ":id") {
      if (mode === "e") {
        this.processType = ProcessType.edit;
      } else {
        this.processType = ProcessType.view;
      }
      this.showMessageProcess = mode === "i" || mode === "u";

      this.messageProcess.title = "Confirmación";
      this.messageProcess.message = "Cargos guardado exitosamente";
      this.messageProcess.type = "success";
      if (mode === "df") {
        this.processType = ProcessType.view;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el cargo porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    } else {
      if (mode === "d") {
        this.processType = ProcessType.create;
        this.messageProcess.title = "Confirmación";
        this.messageProcess.message = "Cargo Eliminado exitosamente";
        this.messageProcess.type = "success";

        this.showMessageProcess = true;
      }

      if (mode === "df") {
        this.processType = ProcessType.edit;
        this.messageProcess.title = "Error";
        this.messageProcess.message =
          "No se puede eliminar el cargo porque tiene información asociada";
        this.messageProcess.type = "error";
        this.showMessageProcess = true;
      }
    }
  }

  /**
    * Nuevo registro
    */
  override newRecord() {
    this.routingState.newRecord(this.route);
  }
  /**
    * Abre alerta para eliminar registro
    */
  dialogEliminar() {
    let dialogSettings = {} as SPlusDialogSettings;
    dialogSettings.title = "Eliminar Cargo";
    dialogSettings.description =
      "¿Estas seguro que desea eliminar este cargo?";
    dialogSettings.actions = [
      { text: "Cancelar" },
      { text: "Si, Eliminar", themeColor: "warning", value: true },
    ];
    this.sPlusFormsDialogsService
      .Show(dialogSettings)
      .pipe(takeUntil(this.unsubcribe$))
      .subscribe((result) => {
        if (result instanceof SPlusDialogCloseResult) {
          console.log("close", result);
        } else {
          if (result.value) {
            this.DeleteRecord();
          }
        }
      });
  }
}
