import { Injectable } from "@angular/core";
import { HttpErrorResponse, HttpClient, HttpParams } from "@angular/common/http";

import { RequestResult } from "src/app/models/General/RequestModel";
import { ControllerApiList } from "@app/models/SaludPlus/Controller/ControllerList";
import { SaludPlusApiResponse } from "@src/app/models/base/SaludplusApiResponse";
import { lastValueFrom, map, Observable, retry } from "rxjs";
import { RequestResultServiceService } from "@src/app/services/Generals/request-result-service.service";
import { Empleados } from "@src/app/models/Nomina/Empleados.model";
import { CargosDTO } from "@src/app/models/Nomina/Cargos";

@Injectable({
  providedIn: "root",
})
export class CargosService {
  static instance: CargosService;
  error: HttpErrorResponse;
  constructor(
    private http: HttpClient,
    private resolveRequestResultService: RequestResultServiceService,) {
      CargosService.instance = this;
  }

  /**
   * Guardar registro
   * @param Datos datos a guardar
   */
  Guardar<T>(Datos: CargosDTO) {
    return this.http.post<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
  }
  /**
   * Actualizar
   * @param Datos datos a actualizar
   * @param id id a actualizar
   */
  ActualizarRegistro<T>(Datos: CargosDTO, id: number) {
    Datos.id = id;
    const record = this.http.put<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}`,
      Datos
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }

  /**
   * Buscar cargo por su id
   * @param id Identificador del registro
   * @returns
   */
  BuscarRegistro<T>(id: number): Observable<T> {
    let params = new HttpParams().set("idCargo", id);
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}`,
      {
        params: params,
      }
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    return record;



  }
  /**
   * Eliminar registro por su id
   * @param id Identificador del registro
   * @returns
   */
  Delete<T>(id: number): Observable<T> {
    let params = new HttpParams().set("idCargo", id);
    const record = this.http.delete<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}`,
      {
        params: params,
      }
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }

  /**
   * Buscar registro por documento
   * @param documento numero de documento
   */
  Validar<T>(nombre: string): Observable<T> {
    const fromObject =
    {
      "nombre": nombre
    }

    let params = new HttpParams({ fromObject: fromObject });

    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}/Validar`, {
      params: params,
    }
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response,false);
    }));
    return record;
  }
  /**
  * Obtener Tipos de documentos
  * @returns Observable<SaludPlusApiResponse<T>>
  */
  ObtenerDepartamentos<T>(): Observable<T> {
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}/Departamentos`
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));

    return record;
  }
  async ObtenerSubDepartamentosAsync<T>(idDepartamento: number): Promise<T> {
    const fromObject =
    {
      "idDepartamento": idDepartamento
    }

    let params = new HttpParams({ fromObject: fromObject });
    const record = this.http.get<SaludPlusApiResponse<T>>(
      `${ControllerApiList.Nomina.Cargos}/SubDepartamentos`, {params:params}
    ).pipe(map(response => {
      return this.resolveRequestResultService.resolve<T>(response);
    }));
    const dataResponse = await lastValueFrom(record);
    return dataResponse;

  }



}
